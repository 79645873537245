<template>
  <div v-if="debugData?.app.creditMemoCreation && debugData?.apiToken">
    <DebugFrame>
      <div class="text-sm text-center rounded-sm leading-2xs">
        <div
          v-if="!success"
          class="bg-status-success-base p-2xs m-2xs"
          @click.prevent.stop="createCreditMemo()"
        >
          Create CreditMemo For Order
        </div>
        <div
          v-else-if="loading"
          class="text-xs bg-status-warn-base p-2xs m-2xs"
        >
          Rödel rödel rödel...
        </div>
        <div v-else class="bg-grey-base p-2xs m-2xs">
          Credit Memo already existing
        </div>
      </div>
    </DebugFrame>
  </div>
</template>

<script setup lang="ts">
import type { Order } from '~/server/transformers/shop/order/types';
import useGetDebugData from '../useGetDebugData';
import DebugFrame from './debugFrame.vue';
import { useSecurePost } from '~/composables/dataFetching/genericFetchers';
import { useUserContext } from '~/stores/useUserContext';

const props = defineProps({
  data: {
    type: Object as PropType<Order>,
    required: true,
  },
});

const debugData = useGetDebugData();
const success = ref(null);
const loading = ref(false);

async function createCreditMemo() {
  try {
    loading.value = true;
    const result = await useSecurePost(
      `/api/${useSiteIdent()}/admin/${debugData.value.apiToken}/debug/shop/history/createCreditMemo`,
      {
        order: props.data,
        customerId: useUserContext().userData.customerId,
        client_id: debugData.value.shopware.client_id,
        client_secret: debugData.value.shopware.client_secret,
      },
    );
    success.value = true;
    // eslint-disable-next-line no-console
    console.debug('Credit Memo created for order:', result);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Could not create Credit Memo for Order', e);
  } finally {
    loading.value = false;
  }
}
</script>
